.menu-wrap {
  .item {
    display: flex;
    align-items: center;
    font-size: 30px;
    color: #fff;
    margin: 10px;
    cursor: pointer;
    -webkit-text-stroke: 1px #464646;
    font-weight: 900;

    button {
      scale: 1 !important;
    }

    @media (pointer: fine) {
      &:hover {
        scale: 1.05;
      }
    }

    &:active {
      scale: 0.95;
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
}

.menu-lobby {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;

  @media only screen and (orientation: landscape) {
    flex-direction: row;

    .item {
      flex: 0.5;
      justify-content: center;
    }
    .empty {
      flex: none !important;
    }

    .confirm {
      flex: none !important;
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }

  .item {
    display: flex;
    align-items: center;
    font-size: 30px;
    color: #fff;
    margin: 10px;
    cursor: pointer;
    -webkit-text-stroke: 1px #464646;
    font-weight: 900;

    button {
      scale: 1 !important;
    }

    @media (pointer: fine) {
      &:hover {
        scale: 1.05;
      }
    }

    &:active {
      scale: 0.95;
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  .confirm {
    width: 7.5vw;
    height: 7.5vw;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: opacity 0.3s ease;
    flex: 1;

    @media only screen and (orientation: portrait) {
      width: 7.5vh;
      height: 7.5vh;
    }
  }

  .empty {
    flex: 1;
  }
}
