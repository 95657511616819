@import '../../../../assets/styles/variables.scss';
.switch {
  position: relative;
  display: inline-block;
  width: 128px;
  min-width: 128px;
  height: 96px;
  min-height: 96px;

  @media screen and (max-width: $tablet-portrait-max) and (orientation: portrait) {
    width: 64px;
    min-width: 64px;
    height: 48px;
    min-height: 48px;
  }
  @media screen and (max-width: $tablet-landscape-max) and (orientation: landscape) {
    width: 64px;
    min-width: 64px;
    height: 48px;
    min-height: 48px;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:disabled + .slider {
      opacity: 0.4;
    }
  }

  &.checked {
    .slider {
      background-color: rgba(255, 168, 0, 0.5);
      &:before {
        background-color: #ffa800;
        transform: translateX(32px);

        @media screen and (max-width: $tablet-portrait-max) and (orientation: portrait) {
          transform: translateX(16px);
        }
        @media screen and (max-width: $tablet-landscape-max) and (orientation: landscape) {
          transform: translateX(16px);
        }
      }
    }
  }
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 48px;
  background-color: rgba(255, 255, 255, 0.5);
  transition: 0.4s;
  cursor: pointer;

  &:before {
    position: absolute;
    content: '';
    height: 96px;
    width: 96px;
    left: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 50%;
    transform: translateX(0);
    transition: 0.4s;
  }
  @media screen and (max-width: $tablet-portrait-max) and (orientation: portrait) {
    border-radius: 24px;
    &:before {
      height: 48px;
      width: 48px;
      transform: translateX(0);
    }
  }
  @media screen and (max-width: $tablet-landscape-max) and (orientation: landscape) {
    border-radius: 24px;
    &:before {
      height: 48px;
      width: 48px;
      transform: translateX(0);
    }
  }
}
