@import '../../../../assets/styles/variables.scss';

.btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  outline: none;
  pointer-events: all;
  padding: 0;
  -webkit-user-select: none !important;
  user-select: none !important;
  background-color: transparent;
  cursor: pointer;
  transition: opacity 0.3s ease;
  @media (pointer: fine) {
    &:hover {
      scale: 1.11;
    }
  }
  &:active {
    scale: 0.89;
  }
  svg {
    display: block;
    width: 100%;
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.button_close {
  position: absolute;
  width: 7.5vw;
  height: 7.5vw;
  right: 1.25vw;
  top: 1.25vw;
  padding: 0;
  z-index: 2;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: opacity 0.3s ease;

  @media only screen and (orientation: portrait) {
    width: 7.5vh;
    height: 7.5vh;
  }
}

.autoPlaySettingBtn {
  width: 128px;
  height: 96px;
  margin: 5px;
  font-size: 40px;
  border-radius: 32px;
  font-weight: 900;
  color: #fff7ae;
  background-color: #fff;
  transition: all 0.3s ease;
  cursor: pointer;
  outline: none;
  font-family: 'Noto Sans';
  box-shadow: inset 0 -4px 0 rgba(#000, 0.25);
  -webkit-text-stroke: 2px #464646;

  &:disabled {
    text-shadow: none;
    cursor: default;
    opacity: 0.5;
  }
  @media (pointer: fine) {
    &:hover:not(:disabled) {
      color: #fff;
      background-color: #ffa800;
      text-shadow: none;
    }
  }
  &.active:not(:disabled) {
    color: #fff;
    background-color: #ffa800;
    text-shadow: none;
  }

  //tablet
  @media screen and (max-width: $tablet-landscape-max) and (orientation: landscape) {
    width: 64px;
    height: 48px;
    font-size: 20px;
    border-radius: 16px;
    margin: 8px;
    -webkit-text-stroke: 1px #464646;
  }
  @media screen and (max-width: $tablet-portrait-max) and (orientation: portrait) {
    width: 64px;
    height: 48px;
    font-size: 20px;
    border-radius: 16px;
    margin: 8px;
    -webkit-text-stroke: 1px #464646;
  }
  // mobile
  @media screen and (max-width: $mobile-landscape-max) and (orientation: landscape) {
    width: 64px;
    height: 48px;
    font-size: 20px;
    border-radius: 16px;
    margin: 8px;
    -webkit-text-stroke: 1px #464646;
  }
  @media screen and (max-width: $mobile-portrait-max) and (orientation: portrait) {
    width: 64px;
    height: 48px;
    font-size: 20px;
    border-radius: 16px;
    margin: 8px;
    -webkit-text-stroke: 1px #464646;
  }
}
