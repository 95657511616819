@import '../../../../assets/styles/variables.scss';
.slider-range {
  height: 96px;
  width: 100%;
  display: flex;
  align-items: center;
  @media screen and (max-width: $tablet-landscape-max) and (orientation: landscape) {
    height: 48px;
  }
  @media screen and (max-width: $tablet-portrait-max) and (orientation: portrait) {
    height: 48px;
  }

  .slider {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0;
    background: transparent;
    outline: none;
    -webkit-appearance: none;
    -webkit-transform: translateZ(0);
  }

  &.isActive {
    input[type='range']::-webkit-slider-thumb {
      background-color: #ffa800;
    }
    input[type='range']::-moz-range-thumb {
      background-color: #ffa800;
    }
    input[type='range']::-ms-thumb {
      background-color: #ffa800;
    }
  }

  &.disabled {
    opacity: 0.6;
    input[type='range']::-webkit-slider-thumb {
      background-color: rgba(255, 255, 255, 0.5);
    }
    input[type='range']::-moz-range-thumb {
      background-color: rgba(255, 255, 255, 0.5);
    }
    input[type='range']::-ms-thumb {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }

  &-wrap {
    position: relative;
    width: 100%;
    height: 32px;

    //tablet
    @media screen and (max-width: $tablet-landscape-max) and (orientation: landscape) {
      height: 16px;
    }
    @media screen and (max-width: $tablet-portrait-max) and (orientation: portrait) {
      height: 16px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      margin: auto;
      border-radius: 48px;
      background-color: #8e8e8e;

      //tablet
      @media screen and (max-width: $tablet-landscape-max) and (orientation: landscape) {
        border-radius: 24px;
      }
      @media screen and (max-width: $tablet-portrait-max) and (orientation: portrait) {
        border-radius: 24px;
      }
    }

    &__progress {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      margin: auto;
      border-radius: 48px 0 0 48px;
      max-width: 90%;
      background-color: #8e630f;

      //tablet
      @media screen and (max-width: $tablet-landscape-max) and (orientation: landscape) {
        border-radius: 24px 0 0 24px;
      }
      @media screen and (max-width: $tablet-portrait-max) and (orientation: portrait) {
        border-radius: 24px 0 0 24px;
      }
    }

    &__control {
      position: relative;
      z-index: 2;
      width: 100%;
    }

    input[type='range'] {
      -webkit-appearance: none;
      width: 100%;
      margin: 0;
      background: transparent;
      outline: none;
      -webkit-transform: translateZ(0);
    }

    input[type='range']::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 96px;
      height: 96px;
      border: 10px solid transparent;
      border-radius: 50%;
      cursor: pointer;
      transform: translate(0, 0);
      background-color: #fff;
      //tablet
      @media screen and (max-width: $tablet-landscape-max) and (orientation: landscape) {
        width: 48px;
        height: 48px;
      }
      @media screen and (max-width: $tablet-portrait-max) and (orientation: portrait) {
        width: 48px;
        height: 48px;
      }
    }
    input[type='range']::-moz-range-thumb {
      width: 96px;
      height: 96px;
      border: 10px solid transparent;
      border-radius: 50%;
      cursor: pointer;
      transform: translate(0, 0);
      background-color: var(--game-color);
      @media screen and (max-width: $tablet-landscape-max) and (orientation: landscape) {
        width: 48px;
        height: 48px;
      }
      @media screen and (max-width: $tablet-portrait-max) and (orientation: portrait) {
        width: 48px;
        height: 48px;
      }
    }
    input[type='range']::-ms-thumb {
      width: 96px;
      height: 96px;
      border: 10px solid transparent;
      border-radius: 50%;
      cursor: pointer;
      transform: translate(0, 0);
      background-color: var(--game-color);
      @media screen and (max-width: $tablet-landscape-max) and (orientation: landscape) {
        width: 48px;
        height: 48px;
      }
      @media screen and (max-width: $tablet-portrait-max) and (orientation: portrait) {
        width: 48px;
        height: 48px;
      }
    }
  }
  &-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    &__item {
      font-size: 10px;
      line-height: 16px;
      color: #000;
    }
  }
}

.isDefault {
  .slider-wrap {
    &:before {
      background-color: #000;
    }

    &__progress {
      background-color: #cacaca;
    }

    input[type='range']::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 14px;
      height: 14px;
      border: none;
      background-color: #fff;
    }

    /* All the same stuff for Firefox */
    input[type='range']::-moz-range-thumb {
      width: 14px;
      height: 14px;
      border: none;
      background-color: #cacaca;
    }

    /* All the same stuff for IE */
    input[type='range']::-ms-thumb {
      width: 14px;
      height: 14px;
      border: none;
      background-color: #cacaca;
    }
  }
}
